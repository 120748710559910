<template>
  <v-card style="min-width: 240px" max-height="620px" class="header-menu">
    <v-window v-model="menuTab" :touch="false">
      <v-window-item :value="MenuTabs.MENU">
        <v-list density="comfortable">
          <v-list-item lines="three">
            <template #prepend>
              <v-icon icon="account_circle" />
            </template>

            <template #title> {{ currentUser?.firstName || "" }} {{ currentUser?.lastName || "" }} </template>

            <template #subtitle>
              <div v-if="currentUser?.email">{{ currentUser.email }}</div>
              <div class="pt-2">
                <nuxt-link to="/settings?tab=PERSONAL_SETTINGS" @click="() => emit('close')">
                  {{ $t("manageAccount") }}
                </nuxt-link>
              </div>
            </template>
          </v-list-item>

          <v-divider class="my-2" />

          <v-list-item @click="() => (menuTab = MenuTabs.LANGUAGE)">
            <template #prepend>
              <v-icon icon="translate" />
            </template>

            <template #title> {{ $t("language") }}: {{ $i18n.locale.toUpperCase() }} </template>

            <template #append>
              <v-icon icon="chevron_right" />
            </template>
          </v-list-item>

          <v-list-item @click="() => (menuTab = MenuTabs.THEME)">
            <template #prepend>
              <v-icon :icon="$vuetify.theme.current.dark ? 'dark_mode' : 'light_mode'" />
            </template>

            <template #title>
              {{ $t("theme") }}: {{ $te(`themes.${currentThemeName}`) ? $t(`themes.${currentThemeName}`) : currentThemeName }}
            </template>

            <template #append>
              <v-icon icon="chevron_right" />
            </template>
          </v-list-item>

          <v-divider class="my-2" />

          <v-list-item link to="/support" @click="() => emit('close')">
            <template #prepend>
              <v-icon :icon="$t('support.icon')" />
            </template>

            <template #title>
              {{ $t("support.title") }}
            </template>
          </v-list-item>

          <v-list-item link to="/settings" @click="() => emit('close')">
            <template #prepend>
              <v-icon :icon="$t('settings.icon')" />
            </template>

            <template #title>
              {{ $t("settings.title") }}
            </template>
          </v-list-item>

          <v-divider class="my-2" />

          <!-- Should be !== 1 and not > 1. In admin panel organizations length is 0 -->
          <v-list-item
            v-if="organizations.length !== 1"
            @click="
              () => {
                toOrganizationsList()
                emit('close')
              }
            "
          >
            <template #prepend>
              <v-icon icon="shuffle" />
            </template>

            <template #title>
              {{ $t("switchOrganization") }}
            </template>
          </v-list-item>

          <v-list-item
            v-if="isPwaDevice"
            @click="
              () => {
                onRefreshClick()
              }
            "
          >
            <template #prepend>
              <v-icon icon="refresh" />
            </template>

            <template #title>
              {{ $t("refresh") }}
            </template>
          </v-list-item>

          <v-list-item
            v-if="isSuperAdmin"
            class="text-admin"
            @click="
              () => {
                toAdminPanel()
                emit('close')
              }
            "
          >
            <template #prepend>
              <v-icon :icon="$t('admin.icon')" />
            </template>

            <template #title>
              {{ $t("admin.title") }}
            </template>
          </v-list-item>

          <v-list-item v-if="isImpersonated && hasOriginalToken" class="text-admin" @click="stopImpersonate">
            <template #prepend>
              <v-icon class="material-symbols-outlined" icon="supervised_user_circle_off" />
            </template>

            <template #title>
              {{ $t("admin.users.exitImpersonate") }}
            </template>
          </v-list-item>

          <v-list-item
            class="text-error"
            @click="
              () => {
                authStore.logout()
                emit('close')
              }
            "
          >
            <template #prepend>
              <v-icon icon="logout" />
            </template>

            <template #title>
              {{ $t("logout") }}
            </template>
          </v-list-item>
        </v-list>
      </v-window-item>

      <v-window-item :value="MenuTabs.LANGUAGE">
        <v-list>
          <v-list-item @click="() => (menuTab = MenuTabs.MENU)">
            <template #prepend>
              <v-icon icon="arrow_back" />
            </template>

            <template #title>
              {{ $t("language") }}
            </template>
          </v-list-item>

          <v-divider class="my-2" />

          <v-list-item
            v-for="lang of availableLocales"
            :key="lang"
            @click="
              () => {
                menuTab = MenuTabs.MENU
                $i18n.setLocale(lang)
              }
            "
          >
            <template #prepend>
              <v-icon v-if="lang === $i18n.locale" icon="check" />
            </template>

            <template #title>
              {{ $t(`languages.${lang}`) }}
            </template>
          </v-list-item>
        </v-list>
      </v-window-item>

      <v-window-item :value="MenuTabs.THEME">
        <v-list>
          <v-list-item @click="() => (menuTab = MenuTabs.MENU)">
            <template #prepend>
              <v-icon icon="arrow_back" />
            </template>

            <template #title>
              {{ $t("theme") }}
            </template>
          </v-list-item>

          <v-divider class="my-2" />

          <v-list-item
            v-for="(themeDefinition, key) in themes"
            :key="key"
            @click="
              () => {
                menuTab = MenuTabs.MENU
                setTheme(key)
              }
            "
          >
            <template #prepend>
              <v-icon v-if="currentThemeName === key" icon="check" />
            </template>

            <template #title>
              {{ $te(`themes.${key}`) ? $t(`themes.${key}`) : key }}
            </template>

            <template #append>
              <div
                class="elevation-4 rounded"
                :style="{
                  border: `1px solid ${themeDefinition.colors.background}`,
                  background: `linear-gradient(to right, ${themeDefinition.colors.primary} 50%, ${themeDefinition.colors.surface} 50%)`,
                  height: '1rem',
                  width: '1rem',
                }"
              />
            </template>
          </v-list-item>
        </v-list>
      </v-window-item>
    </v-window>
  </v-card>
</template>

<script setup lang="ts">
import { useTheme } from "vuetify"

enum MenuTabs {
  MENU = "menu",
  LANGUAGE = "language",
  THEME = "theme",
}

const { themes, name: currentThemeName } = useTheme()
const $router = useRouter()
const { locales } = useI18n()

const availableLocales = computed(
  (): Array<string> => locales.value.map((locale) => (typeof locale === "string" ? locale : locale.code.toString())),
)

const emit = defineEmits(["close"])

const userStore = useUserStore()
const organizationStore = useOrganizationStore()
const authStore = useAuthStore()
const { currentUser } = storeToRefs(userStore)
const { organizations } = storeToRefs(organizationStore)
const { isSuperAdmin, isImpersonated, hasOriginalToken } = storeToRefs(authStore)

const menuTab = ref(MenuTabs.MENU)
const isPwaDevice = ref(false)

const { theme } = useUserPreferences()

onMounted(() => {
  isPwaDevice.value = window.matchMedia("(display-mode: standalone)").matches
})

const onRefreshClick = () => {
  window.location.reload()
}

const setTheme = (value: string) => {
  theme.value = value
}

const toOrganizationsList = async () => {
  await authStore.logoutOrganization()
  location.href = "/?switch_organization=true"
}

const stopImpersonate = async () => {
  emit("close")

  authStore.exitImpersonate()
  await toOrganizationsList()
}

const toAdminPanel = async () => {
  if (!isSuperAdmin.value) return

  await authStore.logoutOrganization()

  $router.push({ name: "admin" })
}
</script>

<template>
  <v-list-item
    v-if="rail || !menuItem.children"
    :title="rail ? undefined : $t(menuItem.title)"
    link
    :to="menuItem.to"
    :disabled="menuItem.disabled"
    :exact="menuItem.exact"
    :style="rail ? `padding: 4px 10px` : undefined"
  >
    <template #prepend>
      <div v-if="rail" style="margin-inline-end: 6px; margin-inline-start: 6px">
        <v-icon class="mt-1 mb-4" :icon="$t(menuItem.icon!)" />
        <div
          class="text-caption text-center position-absolute font-weight-medium text-no-wrap"
          style="font-size: 9px !important; bottom: 0; left: 0; right: 0"
        >
          {{ $t(menuItem.title) }}
        </div>
      </div>

      <v-icon v-else-if="menuItem.icon" :icon="$te(menuItem.icon) ? $t(menuItem.icon) : menuItem.icon" />
    </template>
  </v-list-item>

  <v-list-group v-else>
    <template #activator="{ props: itemProps }">
      <v-list-item v-bind="itemProps" :prepend-icon="$t(menuItem.icon!)" :title="$t(menuItem.title)" :disabled="menuItem.disabled" />
    </template>

    <template #default>
      <v-list class="pa-0" density="comfortable">
        <template v-if="!menuItem.hideDropdownTitle">
          <v-list-item
            :exact="menuItem.exact"
            :title="menuItem.dropdownTitle ? $t(menuItem.dropdownTitle) : `${$t('all')} ${$t(menuItem.title)}`"
            link
            :to="menuItem.to"
            :disabled="menuItem.disabled"
          >
            <template #prepend>
              <v-avatar size="24" style="margin-inline-end: 16px" class="text-white">
                <v-icon icon="fiber_manual_record" size="6px" />
              </v-avatar>
            </template>
          </v-list-item>

          <v-divider class="mx-4" />
        </template>

        <v-list-item
          v-for="child in menuItem.children"
          :key="child.title"
          :title="$te(child.title) ? $t(child.title) : child.title"
          link
          exact
          :active="$route.fullPath.includes(child.to)"
          :to="child.to"
          :disabled="child.disabled"
        >
          <template #prepend>
            <v-avatar
              v-if="child.icon || child.iconLabel"
              size="24"
              variant="elevated"
              :color="child.color && $te(child.color) ? $t(child.color) : undefined"
              :style="`background-color: ${child.color}; margin-inline-end: 16px; border: 1px solid rgba(255, 255, 255, 0.5)`"
              class="text-white"
              :rounded="child.roundedAvatar || undefined"
            >
              <v-icon v-if="child.icon" size="x-small" :icon="(child.icon && $te(child.icon) ? $t(child.icon) : child.icon) ?? undefined" />
              <span v-else class="text-caption font-weight-medium">{{ child.iconLabel }}</span>
            </v-avatar>

            <v-avatar v-else size="24" style="margin-inline-end: 16px" class="text-white">
              <v-icon icon="fiber_manual_record" size="6px" />
            </v-avatar>
          </template>
        </v-list-item>
      </v-list>
    </template>
  </v-list-group>
</template>

<script setup lang="ts">
export interface MenuItem {
  title: string
  icon?: string
  iconLabel?: string
  color?: string
  roundedAvatar?: boolean
  to: string
  disabled?: boolean
  children?: Array<MenuItem>
  exact?: boolean
  dropdownTitle?: string
  hideDropdownTitle?: boolean
}

const props = defineProps<{
  menuItem: MenuItem
  rail?: boolean
}>()
const { menuItem, rail } = toRefs(props)

const $route = useRoute()
</script>
